import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import ScriptTag from 'react-script-tag';

const customBox = (espacio_id, content) => {
  return (
    <Box
      key={espacio_id}
      display='flex'
      alignItems='flex-end'
      bgcolor='background.paper'
      p={'5px'}
      justifyContent='center'>
      {content}
    </Box>
  );
};

const RightColumn: React.FC = () => {

  const [reloadCounter, setReloadCounter] = useState(0);

  useEffect(() => {
    const reloadInterval = setInterval(() => {
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, 60000);

    return () => clearInterval(reloadInterval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      espacios.forEach((item) => {
        const adElement = document.getElementById(`eplAdDiv${item}`);

        if (adElement && (adElement.style.display === 'none' || adElement.childNodes.length === 0)) {
          // @ts-ignore
          adElement.closest('div.MuiBox-root')?.style.setProperty('display', 'none', 'important');
        }
      });
    }, 3000); // Espera un poco para que los anuncios se carguen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const espacios = [
    'Left_468x60', 'Right_468x60', 'Top_300x100', 'Middle_300x100', 'Middle_2_300x100', 'Bottom_300x100',
    'Encuestas_Top', 'Encuestas_Middle', 'Lo_Mas_Comentado_Top', 'Lateral_08',
    'Lateral_09', 'Lateral_10', 'Lateral_11', 'Lateral_12',
    'Lateral_13', 'Lateral_14', 'Lateral_15', 'Pequenos_Sponsors_2', 'Pequenos_Sponsors_1',
  ];

  const funcionesComunes = 'function esperarCargaEPLRightColumn() { return new Promise(resolve => { function checkEPL() { \
	if (typeof(eplAD4M) !== \'undefined\') { resolve(); } else { window.setTimeout(checkEPL, 100); } }; checkEPL(); }); }';

  const resetEPL = 'document.epl = null;';

  return (
    <>
      <ScriptTag
        type='text/javascript'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resetEPL) }}
      />
      <ScriptTag id='Header' type='text/javascript' src='/js/e-planning/snippet_seccion.js' />
      <Box key='AdContainer'>
        <ScriptTag
          id='funcionesComunesRightColumn'
          type='text/javascript'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(funcionesComunes) }}
        />
        {espacios.map((item, index) => {
          const jsCall =
            'async function cargarEspacio' +
            item +
            '() { await esperarCargaEPLRightColumn(); eplAD4M(\'' +
            item +
            '\'); }; cargarEspacio' +
            item +
            '();';

          return customBox(
            item,
            <div key={index}>
              <div id={`eplAdDiv${item}`} key={index} style={{ width: '300px' }} />
              <ScriptTag id='RightColumn' type='text/javascript'
                         dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jsCall) }} />
            </div>,
          );
        })}
      </Box>
    </>
  );
};

export default RightColumn;
