import { Box, Card, Rating, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useIsMobile } from '@providers/Responsive.provider';
import { ReferenceObject } from '@service/model';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import React, { useState } from 'react';

import styles from './Rating.styles';


interface TicketRatingProps {
  reference: ReferenceObject;
  rating: number;
  isExpert: boolean;
}

const TicketRating: React.VFC<TicketRatingProps> = (props) => {
  const classes = styles();

  const [rating, setRating] = useState(props.rating ? props.rating : 0);
  const [showRating, setShowRating] = useState(true);
  const { snackbars } = useCustomSnackbar();
  const isMobile = useIsMobile();

  const handleChangeRating = (newValue: number) => {
    setRating(newValue);

    axios.post<ResponseResult>('/api/tickets/rating', { ticketId: props.reference.referenceId, rating: newValue })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Calificación enviada');
          setShowRating(false);
        } else {
          snackbars.showWarning('Error interno');
        }
      });
  };

  if (!showRating) return null;

  return (
    <Card className={classes.main}>
      <Box className={classes.secondary}>
        <Typography variant={isMobile ? 'subtitle1' : 'h6'} className={classes.ratingText}>
          {props.isExpert ? 'Calificación de la respuesta por el usuario' : '¿Qué te parece la respuesta del experto?'}
        </Typography>

        <Box className={classes.ratingContainer}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Rating
              value={rating}
              size='large'
              className={classes.stars}
              readOnly={props.isExpert || !!props.rating}
              onChange={(event, newValue) => handleChangeRating(newValue)}
            />

            <Box display='flex' justifyContent='space-between' width='100%'>
              <Typography variant='body2' style={{ flex: 1, textAlign: 'left' }}>
                Muy mala
              </Typography>
              <Typography variant='body2' style={{ flex: 1, textAlign: 'right' }}>
                Muy buena
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};


export default TicketRating;
