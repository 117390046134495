import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px',
      boxShadow: '0px 2px 6px #DCDCDC',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      margin: '10px 0',
      overflow: 'inherit',
      [StyleResponsive.onMobile]: {
        overflow: 'unset',
      },
    },

    secondary: {
      marginBottom: '10px',
      display: 'flex',
      padding: '12px',
      flexDirection: 'column',
      alignItems: 'center',
    },

    ratingText: {
      marginBottom: '10px',
      [StyleResponsive.onMobile]: {
        fontSize: '18px',
      },
    },

    ratingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      width: '100%',
      textAlign: 'center',

    },

    stars: {
      fontSize: '40px',
      color: theme.palette.primary.main,
      '& .MuiRating-icon': {
        margin: '0 8px',
      },
    },


  }),
);
